var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.CSCT060P100.001"))),
        ]),
        _c(
          "div",
          { staticClass: "content_box" },
          _vm._l(_vm.cfsImgInfoList, function (item, idx) {
            return _c(
              "div",
              {
                key: item.reqRno,
                class: [
                  "map_wrap",
                  "cfs_photo",
                  idx + 1 < _vm.cfsImgInfoList.length ? "mb-3" : "",
                ],
              },
              [
                _c("img", {
                  staticStyle: { width: "818px", height: "400px" },
                  attrs: { src: item.cfsImgSrc, alt: "" },
                }),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P061.017")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }