<template>
  <div class="popup_wrap" style="width:900px;height:600px;"><!-- 팝업사이즈 참고 : 900*600  popup_wrap -->
    <button
      class="layer_close"
      @click="$emit('close')"
    >close</button>
    <div class="popup_cont" ><!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.CSCT060P100.001') }}</h1><!-- CFS 입고사진 -->
      <div class="content_box"><!-- content_box -->
        <div
          v-for="(item, idx) in cfsImgInfoList"
          :key="item.reqRno"
          :class="['map_wrap', 'cfs_photo', (idx+1) < cfsImgInfoList.length ? 'mb-3' : '']">
          <img
            :src="item.cfsImgSrc"
            alt=""
            style="width:818px;height:400px;"
          />
        </div>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
          <a
            class="button gray lg"
            href="#"
            @click.prevent="$emit('close')"
          >{{ $t('msg.ONEX020P061.017') }}<!-- 닫기 --></a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>

import intergis from '@/api/rest/common/intergis'

// import moment from 'moment'

export default {
  name: 'IntergisCfsDetailPop',
  components: {},
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          oprtDt: '',
          cntrNo: ''
        }
      }
    }
  },
  data: function () {
    return {
      params: {
        oprtDt: '',
        cntrNo: ''
      },
      cfsImgInfoList: []
    }
  },
  created () {
    this.params = this.parentInfo

    intergis.popCfsImg(this.params)
      .then((response) => {
        const resp = response.data
        const cfsImgInfoList = resp.cfsImgInfoList

        this.cfsImgInfoList = []

        if (cfsImgInfoList !== undefined && cfsImgInfoList.length > 0) {
          cfsImgInfoList.forEach(cfsImgInfo => {
            cfsImgInfo.cfsImgSrc = process.env.VUE_APP_COMMON_URL + '/commons/file-download/commons?reqRno=' + cfsImgInfo.reqRno + '&type=INTERGIS'
          })
          this.cfsImgInfoList = cfsImgInfoList
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  mounted () {
  }
}
</script>
